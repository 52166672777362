export const menuRoles = {
    itensMenu: {
        home: {
            roles: [
                'FORNECEDOR_FORNECEDOR_SALVAR',
                'FORNECEDOR_FORNECEDOR_DELETAR',
                'FORNECEDOR_FORNECEDOR_VISUALIZAR'
            ],
            home: {
                visualizar: ['FORNECEDOR_FORNECEDOR_VISUALIZAR'],
                salvar: ['FORNECEDOR_FORNECEDOR_SALVAR'],
                deletar: ['FORNECEDOR_FORNECEDOR_DELETAR']
            }
        },
        fornecedor: {
            roles: [
                'FORNECEDOR_FORNECEDOR_SALVAR',
                'FORNECEDOR_FORNECEDOR_DELETAR',
                'FORNECEDOR_FORNECEDOR_VISUALIZAR',
                'FORNECEDOR_REGULARIZACAO-FORNECEDOR_VISUALIZAR',
                'FORNECEDOR_REGULARIZACAO-FORNECEDOR_SALVAR',
                'FORNECEDOR_REGULARIZACAO-FORNECEDOR_DELETAR'
            ],
            fornecedor: {
                visualizar: ['FORNECEDOR_FORNECEDOR_VISUALIZAR'],
                salvar: ['FORNECEDOR_FORNECEDOR_SALVAR'],
                deletar: ['FORNECEDOR_FORNECEDOR_DELETAR']
            },
            regularizacao: {
                visualizar: ['FORNECEDOR_REGULARIZACAO-FORNECEDOR_VISUALIZAR'],
                salvar: ['FORNECEDOR_REGULARIZACAO-FORNECEDOR_SALVAR'],
                deletar: ['FORNECEDOR_REGULARIZACAO-FORNECEDOR_DELETAR']
            }
        },
        parametros: {
            roles: [
                'PARAMETROS_CONDICAO-PAGAMENTO_DELETAR',
                'PARAMETROS_CONDICAO-PAGAMENTO_VISUALIZAR',
                'PARAMETROS_CONDICAO-PAGAMENTO_SALVAR',
                'PARAMETROS_TIPO-SERVICO_DELETAR',
                'PARAMETROS_TIPO-SERVICO_VISUALIZAR',
                'PARAMETROS_TIPO-SERVICO_SALVAR',
                'PARAMETROS_TIPO-HOMOLOGACAO_DELETAR',
                'PARAMETROS_TIPO-HOMOLOGACAO_VISUALIZAR',
                'PARAMETROS_TIPO-HOMOLOGACAO_SALVAR'
            ],
            condicaoPagamento: {
                visualizar: ['PARAMETROS_CONDICAO-PAGAMENTO_VISUALIZAR'],
                salvar: ['PARAMETROS_CONDICAO-PAGAMENTO_SALVAR'],
                deletar: ['PARAMETROS_CONDICAO-PAGAMENTO_DELETAR']
            },
            tipoServico: {
                visualizar: ['PARAMETROS_TIPO-SERVICO_VISUALIZAR'],
                salvar: ['PARAMETROS_TIPO-SERVICO_SALVAR'],
                deletar: ['PARAMETROS_TIPO-SERVICO_DELETAR']
            },
            tipoHomologacao: {
                visualizar: ['PARAMETROS_TIPO-HOMOLOGACAO_VISUALIZAR'],
                salvar: ['PARAMETROS_TIPO-HOMOLOGACAO_SALVAR'],
                deletar: ['PARAMETROS_TIPO-HOMOLOGACAO_DELETAR']
            }
        }
    }
};

/** Roles personalizadas conforme precisar em alguma aplicação
 * abaixo alguns exemplos
 */
export const rolesPersonalizadas = {
    rh: {
        visualizar: ['PERSONALIZADAS_RH_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_RH_SALVAR']
    },
    compras: {
        visualizar: ['PERSONALIZADAS_COMPRAS_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_COMPRAS_SALVAR']
    },
    compliance: {
        visualizar: ['PERSONALIZADAS_COMPLIANCE_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_COMPLIANCE_SALVAR']
    },
    seguranca: {
        visualizar: ['PERSONALIZADAS_SEGURANCA_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_SEGURANCA_SALVAR']
    },
    ambularotio: {
        visualizar: ['PERSONALIZADAS_AMBULATORIO_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_AMBULATORIO_SALVAR']
    },
    juridico: {
        visualizar: ['PERSONALIZADAS_JURIDICO_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_JURIDICO_SALVAR']
    }
};
