import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles, rolesPersonalizadas } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.home.home.visualizar
        }
    },
    {
        path: 'fornecedor',
        loadChildren: () =>
            import('./pages/fornecedor/fornecedor.module').then((m) => m.FornecedorModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.fornecedor.fornecedor.visualizar
        }
    },
    {
        path: 'regularizacao',
        loadChildren: () =>
            import('./pages/regularizacao/regularizacao.module').then((m) => m.RegularizacaoModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.fornecedor.regularizacao.visualizar
        }
    },
    {
        path: 'dados-bancarios',
        loadChildren: () =>
            import('./pages/dados-bancarios/dados-bancarios.module').then(
                (m) => m.DadosBancariosModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: rolesPersonalizadas.compras.visualizar
        }
    },
    {
        path: 'visualizacao-avaliacao-conformidade/:id',
        loadChildren: () =>
            import('./pages/view-avaliacao-conformidade/view-avaliacao-conformidade.module').then(
                (m) => m.ViewAvaliacaoConformidadeModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: [
                ...menuRoles.itensMenu.fornecedor.fornecedor.visualizar,
                ...rolesPersonalizadas.rh.visualizar,
                ...rolesPersonalizadas.compliance.visualizar
            ]
        }
    },
    {
        path: 'parametros/condicao-pagamento',
        loadChildren: () =>
            import('./pages/parametros/condicao-pagamento/condicao-pagamento.module').then(
                (m) => m.CondicaoPagamentoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.condicaoPagamento.visualizar
        }
    },
    {
        path: 'parametros/tipo-servico',
        loadChildren: () =>
            import('./pages/parametros/tipo-servico/tipo-servico.module').then(
                (m) => m.TipoServicoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.tipoServico.visualizar
        }
    },
    {
        path: 'parametros/tipo-empresa',
        loadChildren: () =>
            import('./pages/parametros/tipo-homologacao/tipo-homologacao.module').then(
                (m) => m.TipoHomologacaoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.tipoHomologacao.visualizar
        }
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
