export const environment = {
    production: false,
    APP_URL_LOGIN: 'https://appdev.tvsbt.com.br/suite/login/api/v1/login',
    ACCESS_TOKEN: 'dev_access_token',
    REFRESH_TOKEN: 'dev_refresh_token',
    URL_BASE: 'https://appdev.tvsbt.com.br/suite',
    PATH: '/portalfornecedoradm',
    URL_LOGOUT: 'https://login-h.tvsbt.com.br/login',
    URL_LOGIN: 'https://login-h.tvsbt.com.br/login?redirectBack=portalfornecedoradm',
    MODE: 'dev',
    LOCAL_STORAGE_KEY: '16b350b6e1b3e7f4aa24ebf1e57a1eab',
    LOCAL_STORAGE_IV: '0000000000000000',
    APP: 'sbt-portalfornecedoradm',
    LINK_FREELANCER: 'https://portalfreelanceradm-h.tvsbt.com.br'
};
